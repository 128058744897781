$theme-colors: (
  "primary": #d2246c,
  "danger": #ffa600
);

$body-bg : #584a44;

.custom-well{
    background-color: rgba(255,255,255,.2);
    padding: 25px;
    border-radius: 10px;

    h4 {
        margin: 0;
        padding: 0;
        font-weight: 100;
        color: lighten($color: $body-bg, $amount: 65%);
    }

    &.custom-well-dark {
        background-color: rgba(0,0,0,.4);
        border: 2px dashed black;

        ul {
            color: lighten($body-bg, 65%);
            margin: 0;
            padding: 0;
            margin-top: 20px;
            
            li {
                list-style:  none;
                display: block;
                margin: 10px;
                padding: 10px;
                background: rgba(0,0,0,.2);

                h5 {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}

img.company-logo {
    max-width: 200px;
  }
  

@import '~bootstrap/scss/bootstrap.scss';